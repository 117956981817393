<template>
    <tr class="text-center">
        <td colspan="8" class="m-auto">
            <div class="paginator">
                <div class="" style="width: 80px !important">
                    <v-select
                        class="text-right w-100"
                        label="Table Size"
                        :items="['5', '10', '20', '50', '100']"
                        variant="underlined"
                        v-model="conditions[pageSize]"
                        v-on:change="goToPageEmit('first')"
                    ></v-select>
                </div>
                <div class="align-center mr-10 ml-3">
                    <v-icon
                        class="black-color cursor-pointer"
                        @click="page > 1 && goToPageEmit('back')"
                    >
                        mdi-chevron-left
                    </v-icon>
                    <span class="current-page"
                        >{{ page }} of {{ total_count }}</span
                    >
                    <v-icon
                        class="black-color cursor-pointer black-color"
                        @click="page < total_count && goToPageEmit('next')"
                        large
                    >
                        mdi-chevron-right
                    </v-icon>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    data() {
        return {
            pageSize: 'pageSize',
        }
    },
    props: {
        goToPage: Function,
        total_count: Number,
        page: Number,
        conditions: Object,
    },
    methods: {
        goToPageEmit(action) {
            this.$emit('goToPage', action)
        },
    },
}
</script>
<style src="../assets/css/Table.css" scoped></style>
