<template>
  <div class="blackdrop" @click.self="closeModel">
    <div class="modal_shipment" :class="isAddModel ? addModal : ''">
      <div class="modelHeader">
        <h3>Picking and Shipping</h3>
        <span
          @click.self="closeModel"
          style="cursor: pointer; align-self: center; margin-bottom: 0px"
          >X</span
        >
      </div>
      <v-progress-circular v-if="loadingModel" indeterminate rounded="0">
      </v-progress-circular>
      <div class="modelContent" v-else>
        <form class="modalform" @submit.prevent>
          <div class="modelContent2">
            <div class="formcotrol" v-if="isAddModel">
              <label for="pick">Picked Quantity</label>
              <input
                id="ship"
                type="text"
                v-model="pickingShippingData.pickedQuantity"
                :disabled="!isAddModel"
              />
            </div>
            <div class="formcotrol" v-if="isAddModel">
              <label for="ship">Shipped Quantity</label>
              <input
                id="ship"
                type="text"
                v-model="pickingShippingData.shippedQuantity"
                :disabled="!isAddModel"
              />
            </div>
            <div class="formcotrol">
              <label for="warehouse">Warehouse</label>
              <select
                v-model="pickingShippingData.warehouseName"
                :disabled="!isAddModel"
              >
                <option
                  v-for="ware_house in ware_house_details"
                  :key="ware_house"
                  :value="ware_house"
                >
                  {{ ware_house }}
                </option>
              </select>
            </div>
            <div class="formcotrol">
              <label for="warebin">Warehouse Bin</label>
              <select
                id="warebin"
                v-model="pickingShippingData.warehouseBinName"
                :disabled="!isAddModel"
              >
                <option
                  v-for="ware_house_bin in ware_house_bin_details"
                  :key="ware_house_bin"
                  :value="ware_house_bin"
                >
                  {{ ware_house_bin }}
                </option>
              </select>
            </div>
            <div class="formcotrol">
              <label for="shipMethod">Shipping Method</label>
              <select
                id="ship"
                v-model="pickingShippingData.shipmentId"
                :disabled="!isAddModel"
              >
                <option
                  v-for="shipment_method in shipment_method_details"
                  :key="shipment_method.id"
                  :value="shipment_method.id"
                >
                  {{ shipment_method.name }}
                </option>
              </select>
            </div>
            <div class="formcotrol">
              <label for="tracking">Tracking Number</label>
              <input
                id="tracking"
                type="text"
                v-model="pickingShippingData.trackingNumber"
                :disabled="!isAddModel"
              />
            </div>
            <div class="formcotrol" v-if="!isAddModel">
              <label for="status">Shipping Status</label>
              <div
                class="textarea"
                style="
                  width: 90% !important;
                  color: rgb(100, 99, 99) !important;
                "
                type="text"
                id="shipDate"
                ref="textarea"
              >
                {{ pickingShippingData.Delivery_Status }}
              </div>
            </div>
            <div class="formcotrol" v-if="!isAddModel">
              <label for="ExpiryDate">Ex. Date of Arrival</label>
              <input
                type="date"
                id="expire"
                v-model="pickingShippingData.expectedDeleivery"
                :disabled="!isAddModel"
              />
            </div>
            <div
              class="button-bar"
              style="margin-bottom: 10px"
              v-if="isAddModel"
            >
              <v-btn class="add" @click="addShipment">Add</v-btn>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pickingShippingData: Object,
    isAddModel: Boolean,
    add: Function,
    update: Function,
    loadingModel: Boolean,
    close: Function,
    ware_house_details: Array,
    ware_house_bin_details: Array,
    shipment_method_details: Array,
  },
  data() {
    return {
      addModal: "addModal",
    };
  },
  methods: {
    resizeTextarea() {
      alert("here");
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto"; // Reset the height to auto
      textarea.style.height = eval(textarea.scrollHeight + 100) + "px"; // Set the height to match the content
    },
    closeModel() {
      this.$emit("close");
    },
    async addShipment() {
      this.$emit("add");
    },
  },
};
</script>
<style src="../../assets/css/Shipment.css" scoped></style>
