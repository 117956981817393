<template>
  <!-- <div>
  <v-pr type="card"></v-pr>  
</div> -->
  <NavBar />
  <router-view />
</template>
<script>
import NavBar from "./components/NavBar.vue";
import { debounce } from "lodash";
import store from './store/store'
export default {
  data(){
    return{
      store: store,
    }
  },
  computed:{

  },
  components: {
    NavBar,
  },
};
</script>
<style lang="scss" src="./assets/style/main.scss"></style>
