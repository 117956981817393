<template>
  <div class="login_container d-flex justify-center w-100 h-100 align-center">
    <div
      class="color-blueshade container-size d-flex justify-center align-center"
    >
      <v-btn
        class="button-color-purple button-size font-weight-light d-flex justify-center align-center"
        size="large"
        @click="handleLogin"
      >
        <v-icon class="icon-width">
          <img
            :src="require('@/assets/microsoft.png')"
            class="image-size"
            alt="Microsoft Logo"
          />
        </v-icon>
        Login with microsoft
      </v-btn>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import { useRouter } from "vue-router";

import Card from "@/components/Card.vue";
import microsoftlogo from "@/assets/microsoft.png";

import store from "../store/store";
import { PublicClientApplication } from "@azure/msal-browser";

const clientId = "4fcdc8fe-1e78-401c-bf5f-045746ff6879";
const tenantId = "426ba34f-3f7e-475d-a39f-5b3440cf93c8";

import {
  handle_email,
  handle_otp,
} from "../services/authenticate/authenticate";
import { useToast } from "vue-toast-notification";
export default {
  components: {
    Button,
    Card,
  },
  data() {
    return {
      router: useRouter(),

      store: store,

      toast: useToast(),
    };
  },
  methods: {
    async handleLogin() {
      try {
        const authConfig = {
          auth: {
            clientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri: process.env.VUE_APP_MICROSOFT_REDIRECT_URI,
          },
        };

        var client = new PublicClientApplication(authConfig);

        // Initialize MSAL
        await client.initialize();

        // Handle redirect promise
        await client.handleRedirectPromise();

        // Show login prompt
        var loginRequest = {
          scopes: ["user.read"],
        };
        await client.loginPopup(loginRequest);

        var tokenRequest = {
          scopes: ["user.read"],
          account: client.getAllAccounts()[0], // Assuming there is an account after successful login
        };

        let tokenResponse = await client.acquireTokenSilent(tokenRequest);

        if (!tokenResponse?.accessToken) {
          console.error("Access token is not available.");
          return;
        }

        this.store.dispatch("performSetAccesstoken", tokenResponse.accessToken);
        // FIXME: remove localStorage and use persist-storage
        localStorage.setItem("access_token", tokenResponse.accessToken);
        this.router.push("/dashboard");
      } catch (error) {
        console.error("An error occurred during login:", error);
      }
    },
  },
};
</script>

<style src="../assets/css/Authenticate.css" scoped></style>
