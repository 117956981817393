<template>
  <nav class="nav">
    <header class="header">
      <img src="../assets/decypher.png" alt="Logo" height="100" width="100" />
      <div class="link">
        <router-link to="/"></router-link>
        <router-link to="/support"></router-link>
        <button @click="logout" style="display: flex; margin-right: 15px">
          <img src="../assets/logout.svg" />
          <span
            style="align-self: center; margin-top: -2.7px; margin-left: -12px"
            >Logout</span
          >
        </button>
      </div>
    </header>
  </nav>
</template>
<script>
import "../assets/css/NavBar.css";
import { useRouter } from "vue-router";
import logout from "../assets/logout.svg";
export default {
  data() {
    return {
      router: useRouter(),
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("access_token");
      this.router.push("/");
    },
  },
};
</script>
<style scoped>
img {
  margin-right: 5px;
}
</style>
