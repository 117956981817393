import { axiosInstance } from '../index'
const get_warehouse_details = async (page) => {
    return await axiosInstance.get(`api/v1/warehouses?page=${page}`)
}
const get_warehouse_bin_details = async () => {
    return await axiosInstance.get('api/v1/warehouseBins')
}
export {
    get_warehouse_details,
    get_warehouse_bin_details
}
