<template>
    <div class="filter-container">
        <v-autocomplete
            v-model="conditions[paramName]"
            v-model:search="searchInput"
            :items="options"
            :label="label"
            multiple
            variant="solo-filled"
            density="compact"
            :loading="!options?.length"
            :clearable="!loading"
            class="wrap-text"
            :style="computedBackgroundStyle"
        >
            <template v-slot:selection="{ item, index }">
                <span v-if="index < 1">{{ item.title?.slice(0, 5) }}</span>
                <span
                    v-if="index === 1"
                    class="text-grey text-caption align-self-center"
                >
                    (+{{ conditions[`${paramName}`]?.length - 1 }})
                </span>
            </template>

            <template #item="{ item, active, selected }">
                <div
                    class="custom-item"
                    :style="computedBackgroundStyle"
                    @click="toggleItem(item.title)"
                    :class="{ active: active, selected: selected }"
                >
                    <v-checkbox
                        :value="item.title"
                        :disabled="true"
                        v-model="conditions[paramName]"
                    />
                    <span
                        class="item multiline-title"
                        :class="computedBackgroundStyle"
                        >{{ item.title?.replace(/[@/]/g, '') }}
                        <v-tooltip
                            v-if="item.title?.length > 46 && isWideEnough"
                            activator="parent"
                            location="bottom"
                            max-width="200"
                            >{{ item.title }}</v-tooltip
                        >
                        <v-tooltip
                            v-else-if="item.title?.length > 100"
                            activator="parent"
                            location="bottom"
                            max-width="200"
                            >{{ item.title }}</v-tooltip
                        >
                    </span>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: 1,
            searchInput: '',
            isWideEnough: window.innerWidth >= 616,
            open: false,
        }
    },
    props: {
        options: Array,
        label: String,
        conditions: Object,
        paramName: String,
        Emit: Function,
        loading: Boolean,
    },
    watch: {
        conditions: {
            handler: function () {
                this.searchInput = ''
            },
            deep: true,
            lazy: true,
        },
    },
    computed: {
        computedBackgroundStyle() {
            if (this.loading) {
                return {
                    pointerEvents: 'none',
                }
            } else {
                return {} // No background style when not disabled
            }
        },
        isDisabled() {
            return this.loading
        },
    },
    methods: {
        handleWindowResize() {
            this.isWideEnough = window.innerWidth >= 800 // Recalculate the value on window resize
        },
        toggleItem(item) {
            if (!this.loading) {
                try {
                    if (!this.conditions[`${this.paramName}`]) {
                        this.conditions[`${this.paramName}`] = []
                    }
                    if (this.conditions[`${this.paramName}`].includes(item)) {
                        this.conditions[`${this.paramName}`] = this.conditions[
                            `${this.paramName}`
                        ]?.filter((_item) => _item !== item)
                    } else {
                        this.conditions[`${this.paramName}`].push(item)
                    }
                } catch {
                    console.log('Error')
                }
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss" src="../assets/css/Filter.scss" scoped></style>
