<template>
  <div class="table-responsive bg-white pb-5 ml-10 table-width mb-10">
    <table class="table m-0 ma-10 table-hover">
      <thead class="text-white">
        <tr>
          <th
            class="border-0 p-4 ps-0"
            v-for="(header, index) in pickingHeaders"
            :key="index"
          >
            <span>{{ header.text }}</span>
          </th>
        </tr>
      </thead>
      <tbody
        class="accordion"
        id="accordionExample"
        v-if="pickingShippingDetails?.length"
      >
        <template
          v-for="(picking, index) in pickingShippingDetails"
          :key="index"
        >
          <tr
            :id="`heading${index}`"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${index}`"
            aria-expanded="true"
            :aria-controls="`collapse${index}`"
            @click="get_picking_details_by_id(picking.id)"
          >
            <td
              v-for="key in pickingHeaders"
              :key="key"
              class="p-4 pe-3 ps-0 text-center"
            >
              <span :id="picking[key.name]" :class="getClass(key)">
                <span v-if="picking[key.name] == '0' || picking[key.name]">
                  {{
                    typeof picking[key.name] == "string"
                      ? picking[key.name]?.slice(0, 100)
                      : picking[key.name]
                  }}
                </span>
                <span v-else> NIL </span>
                <span v-if="picking[key.name]?.length > 100">...</span>
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  max-width="300"
                  min-width="300"
                  v-if="picking[key.name]?.length > 100"
                  >{{ picking[key.name] }}</v-tooltip
                >
              </span>
            </td>
          </tr>
        </template>
        <Paginator
          class="not-hover"
          @goToPage="goToPage"
          :total_count="total_count"
          :page="page || 1"
          :conditions="conditions"
          :pageSize="pageSize"
        />
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="9">No data Available</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { get_picking_shipping_details } from "@/services/ShipmentDetails/picking_shipping_details";
import Paginator from "./Paginator.vue";
export default {
  components: {
    Paginator,
  },
  props: {
    pickingShippingDetails: Array,
    hanldeClickRow: Function,
    getPickingShippingCall: Function,
    message: String,
    total_count: Number,
    _page: Number,
    conditions: Object,
  },
  data() {
    return {
      page: this._page,
      index: 0,
      currentPage: 1,
      pageSize: "pageSize",
    };
  },
  watch: {
    async page(_new, old) {
      {
        if (_new != old) {
          await this.$emit("getPickingShippingCall", this.page);
        }
      }
    },
    conditions: {
      handler: function (newValue, oldValue) {
        if (newValue.pageSize) {
          this.page = 1;
        }
      },
      deep: true,
      lazy: true,
    },
  },
  methods: {
    getClass(key) {
      if (key.name == "customerDescription")
        return "hover-text two-liner description";
      if (key.name == "companyName") return "hover-text two-liner company";
      if (key.name == "warehouse") return "hover-text warehouse";
      if (key.name == "qtyShip" || key.name == "qtyPick") return "w-20";
      else {
        return "hover-text another";
      }
    },
    goToPage(action) {
      if (action == "next") {
        this.page++;
      } else if (action == "first") {
        this.page = 1;
      } else {
        this.page--;
      }
      // if (currentPage > 0 && currentPage <= this.total_count) {
      //   this.page = currentPage;
      // }
    },
    async get_picking_details_by_id(id) {
      await this.$emit("hanldeClickRow", id);
    },
    async toggleDropdown(id) {
      this.loading = true;
      this.picking_data = [];
      await get_picking_shipping_details(id)
        .then((res) => {
          this.loading = false;
          this.picking_data = res?.data;
        })
        .catch((err) => {
          this.picking_data = [];
          this.message = "no record available";
        });
    },
    handleClick(product_id, shipping_id) {
      this.$emit("handleRow", product_id, shipping_id);
    },
    handleClose() {
      this.show_pick_table = false;
    },
  },
  computed: {
    pickingHeaders() {
      return [
        { name: "companyName", text: "Company" },
        { name: "qtyShip", text: "Qty Ship" },
        { name: "qtyPick", text: "Qty Pick" },
        { name: "customerDescription", text: "Description" },
        { name: "warehouse", text: "Warehouse" },
        { name: "Ship Method", text: "Ship Method" },
        { name: "trackingNumber", text: "Tracking" },
      ];
    },
  },
};
</script>

<style src="../assets/css/Table.css" scoped></style>
