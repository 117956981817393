<template>
  <v-snackbar multi-line v-model="show" :color="color">
    {{ text }}
    <template v-slot:actions>
      <v-btn :color="white" variant="text" @click="closeSnackBar()">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      text: "",
      color: "",
      show: false,
    };
  },
  methods: {
    closeSnackBar() {
      this.show = false;
    },
  },
  created() {
    this.emitter.on("snackbar", ({ text, color = "info" }) => {
      this.text = text;
      this.color = color;
      this.show = true;
    });
  },
};
</script>
