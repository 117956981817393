import axios from "axios";
export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});
axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("state_token");
    // if(token){
    config.headers.Authorization = `Bearer ${process.env.VUE_APP_AUTHORIZATION_KEY}`;
    config.headers.ClientID = `${process.env.VUE_APP_CLIENT_ID}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);
