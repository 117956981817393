<template>
  <div>
    <input
      :type="type"
      class="w-100 pa-3 mt-2 border-raduis"
      style="background-color: rgb(255, 255, 255) ;color:black"
      color="black"
      :placeholder="placeholder"
      :label="label"
      v-model="input_value"
    />
  </div>
</template>
<script>
import store from "../store/store";
export default {
  props: {
    placeholder: String,
    label: String,
    type:String
  },
  data() {
    return {
      input_value: "",
      store: store,
    };
  },
  watch:{
    input_value(){
      if (this.label == "Email") {
        this.store.dispatch("performSetEmail", this.input_value);
      }
      else if(this.label=="OTP"){
        this.store.dispatch("performOtp",this.input_value)
      }
    }
  },
};
</script>
<style>
input::placeholder{
  color: rgb(95, 95, 95);
}
</style>