import { createStore } from 'vuex'
const store = createStore({
  state () {
    return {
      access_token:null,
      email:null,
      otp:null,
      loading:false,
    }
  },
  mutations: {
    setAccessToken(state,access_token) {
      state.access_token = access_token
    },
    setEmail(state,email){
      state.email=email
    },
    setOtp(state,otp){
      state.otp=otp
    },
    setLoading(state,loading){
      state.loading = loading
    }
  },
  getters:{
      getAccessToken(state){
        return state.access_token;
      },
      getEmail(state){
        return state.email
      },
      getOtp(state){
        return state.otp
      },
      getLoading(state){
        return state.loading
      }
  },
  actions:{
      performSetAccesstoken(context,access_token){
        context.commit('setAccessToken',access_token)
      },
      performSetEmail(context,email){
        context.commit('setEmail',email)
      },
      performOtp(context,otp){
        context.commit('setOtp',otp)
      },
      performLoading(context,loading){
        context.commit('setLoading',loading)

      }
  }
})

export default store;