export const setQuerry = (newValue,router) => {
    let customerDescription = newValue['customerDescription'] ? newValue['customerDescription'].join("|"):undefined
    let company_details = newValue['company_details']? newValue['company_details'].join('|'):undefined
    let ware_house_bin_search = newValue['ware_house_bin_search'] ? newValue['ware_house_bin_search'].join('|') : undefined 
    let shipment_status_search = newValue['shipment_status_search'] ? newValue['shipment_status_search'].join('|') : undefined
    let ware_house_search = newValue['ware_house_search'] ? newValue['ware_house_search'].join('|') : undefined
    let created = "less than 90 days"
    let pageSize = newValue['pageSize']?.length ? newValue['pageSize']:10
    router.push({
        path: "/dashboard",
        query: {
          company_details,customerDescription, ware_house_bin_search, shipment_status_search , ware_house_search , created,pageSize
        },
      });
}