<template>
    <div
    class="d-flex flex-column justify-center pa-16 border-raduis"
    style="background-color: rgba(67, 67, 92, 0.2)"
  >
    <div class="pb-5">
      <h2 style="color: #fec28e; text-align: left">Login</h2>
      <p class="text-white" style="display: block;  text-align: left; color:rgb(172, 168, 193) !important">
        {{ paragraph_text }}
      </p>
    </div>
    <form
      class="flex w-full flex-col justify-center space-y-10"
      @submit.prevent
    >
      <div>
        <label class="text-left text-white w-100 d-block">{{ label }}</label>
        <div class="relative">
          <Input :label="label" :type="type" :placeholder="placeholder_text"/>
        </div>
        <p class="mt-1 text-red-500"></p>
      </div>
     <Button @handleEvent="submit" />
    </form>
  </div>
  <div></div>
</template>

<script>
import Button from './Button.vue'
import Input from './Input.vue';
export default {
    props:{
        paragraph_text:String,
        placeholder_text:String,
        label:String,
        handleSubmit:Function,
        type:String,
    },
    components:{
        Button,
        Input
    },
    methods:{
        submit(){
            this.$emit("handleSubmit")
        }
    }
}
</script>

<style scoped>
h2{
    font-size: 2.0rem;
}
</style> 