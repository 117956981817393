
export const  setCondition = (query) => {
    return {
        company_details : query.company_details?query.company_details?.split('|'):undefined,
        customerDescription : query.customerDescription?query.customerDescription?.split("|"):undefined ,
        ware_house_search : query.ware_house_search?query.ware_house_search?.split('|'):undefined,
        ware_house_bin_search : query.ware_house_bin_search?query.ware_house_bin_search:undefined,
        shipment_status_search : query.shipment_status_search?query.shipment_status_search?.split('|'):undefined,
        pageSize:query.pageSize? query.pageSize:10
    }
}