import Dashboard from '../views/Dashboard.vue'
import SupportView from '../views/SupportView.vue'
import Authenticate from  '../views/Authenticate.vue'
export const routes =  [
    {
      path:'/',
      name:'authenticate',
      component:Authenticate
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/support',
      component: SupportView
    },
]