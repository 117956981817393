<template>
  <section class="message">
    <div class="numberlist">
      <div class="search">
        <input type="text" placeholder=" Search Contact" />
      </div>

      <div class="tab">
        <div class="icon">
          <v-icon start icon="mdi-message"></v-icon>
        </div>
        <div class="icon-w">
          <v-icon start icon="mdi-whatsapp"></v-icon>
        </div>
      </div>
      <div class="list">
        <a href="#">234234</a>
      </div>
      <div class="list">
        <a href="#">234234</a>
      </div>
      <div class="list">
        <a href="#">234234</a>
      </div>
    </div>
    <div class="chatbox">
      <div class="list-1">
        <span
          class="listOfMessages"
          v-for="message in messages"
          :key="message"
          >{{ message.body }}</span
        >
      </div>
      <div class="chat">
        <v-text-field
          label="message"
          variant="outlined"
          v-model="message"
        ></v-text-field>
        <v-btn @click="hanldeMessage()">Reply</v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      to: "",
      from: "",
      message: "",
      messages: [],
    };
  },
  methods: {
    async hanldeMessage() {
      let message = this.message;
      await axios
        .get(`http://localhost:5001/message?message=${message}`)
        .then((res) => {
          this.message = res.data.phone_number;
        });

      await axios
        .get(`http://localhost:5001/getMessage`)
        .then((res) => {
          this.messages = res.data;
        })
        .catch((err) => console.log(err));
    },
  },
  async created() {
    await axios
      .get(`http://localhost:5001/getMessage`)
      .then((res) => {
        this.messages = res.data;
        const reversedArray = [];

        for (let i = this.messages.length - 1; i >= 0; i--) {
          const valueAtIndex = this.messages[i];
          reversedArray.push(valueAtIndex);
        }
        this.messages = reversedArray;
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style slang="scss" scoped>
input:focus {
  border: none;
  background: green;
}
.message {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  column-gap: 2%;
  min-height: 80%;
  line-height: 80%;
  max-width: 90%;
  margin: 1% auto;
}
::v-deep .v-field__input {
  width: 80% !important;
}
.v-btn {
  width: 10%;
  margin: auto;
  background: rgb(29, 27, 27);
  color: white;
}
.chat {
  width: 80%;
  align-self: end;
  justify-self: center;
  margin-bottom: 1%;
}
.numberlist {
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.chatbox {
  background: white;
  display: grid;
}
.list {
  width: 100%;
  padding: 8%;
  border-bottom: 1px solid rgb(235, 235, 235);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list:hover {
  border-left: 1px solid black;
  border-right: 1px solid black;
  background: rgb(252, 251, 251);
  cursor: pointer;
}
a {
  text-decoration: none;
  color: rgb(73, 73, 73);
}
.search {
  width: 100%;
  padding: 8% 0%;
  border-bottom: 1px solid rgb(235, 235, 235);
}
.search input {
  width: 90%;
  background: rgb(235, 235, 235);
  padding: 5% 0.4%;
  text-align: left;
}

.list-1 {
  display: grid;
}

.listOfMessages:nth-child(odd) {
  text-align: left;
  display: block;
  background: rgb(230, 230, 230);
  margin: 1%;
  padding: 1%;
  width: 40%;
  height: 50%;
}

.listOfMessages:nth-child(even) {
  text-align: left;
  display: block;
  justify-self: end;
  border: 1px solid rgb(224, 224, 224);
  box-shadow: 0px 0px 3px 3px rgb(224, 224, 224);
  margin: 1%;
  padding: 1%;
  width: 40%;
  margin-right: 4%;
  height: 50%;
}
.v-btn {
  background: transparent;
  box-shadow: none;
}
.tab {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.tab {
  background: rgb(240, 240, 240);
}
.icon {
  border-right: 1px solid black;
}
.icon,
.icon-w {
  padding: 4%;
}
.icon:hover,
.icon-w:focus {
  background: grey;
}
</style>
