<template>
  <div class="list_view">
    <div class="greyout" v-if="loading">
      <v-progress-circular indeterminate rounded="0"> </v-progress-circular>
    </div>
    <div class="header">
      <h1>Shipment Details</h1>
      <p>Explore your Shipments on any of your products</p>
    </div>

    <Filter
      :ware_house_detail="ware_house_details"
      :customerDescription="customerDescription"
      :conditions="conditions"
      :all_shipment_computed="all_shipment_computed"
      @reset="ResetSearchModel()"
      @handleAdd="handleAdd()"
      :all_companies="companies_detail"
      :loading="loading"
    />
    <Tabels
      v-if="!loading"
      @hanldeClickRow="hanldeClickRow"
      :pickingShippingDetails="pickingShippingDetails"
      @getPickingShippingCall="getPickingShippingCall"
      :conditions="conditions"
      :message="message"
      :total_count="total_count"
      :_page="page"
    />
    <ShippmentModel
      v-if="onShowModel"
      :isAddModel="isAddModel"
      @close="closeModel()"
      :pickingShippingData="pickingShippingData"
      :loadingModel="loadingModel"
      @add="addShipment"
      :ware_house_details="ware_house_details"
      :ware_house_bin_details="ware_house_bin_details"
      :shipment_method_details="shipment_method_details"
    />
    <div>
      <Snackbar />
    </div>
  </div>
</template>

<script>
import Tabels from "../components/Tabels.vue";
import { convert_to_form_format } from "../utils/form_convertor";
import ShippmentModel from "../components/modals/ShippmentModel.vue";
import Snackbar from "../components/Snackbar.vue";
import Filter from "../components/Filter.vue";
import {
  get_picking_shipping_details_id,
  get_picking_shipping_details,
  getCompanies,
  getShipmentMethod,
  getCustomerDescription,
  add_picking_shipping_detials,
} from "../services/ShipmentDetails/picking_shipping_details";
import { convert_to_json_format } from "../utils/json_converter";

import {
  get_warehouse_details,
  get_warehouse_bin_details,
} from "../services/WarehouseDetails/ware_house_details";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import { setCondition } from "../utils/condition_setter";
import { setQuerry } from "../utils/query_setter";
import store from "../store/store";

export default {
  components: {
    ShippmentModel,
    Snackbar,
    Filter,
    Tabels,
  },
  data() {
    return {
      page: 1,
      line: "",
      ware_house_search: [],
      data: [],
      store: store,
      loading: false,
      loadingModel: false,
      shipmentId: "",
      onShowModel: false,
      isAddModel: false,
      productID: 100001,
      products: [],
      pickingShippingData: {},
      pickingShippingDetails: [],
      searchModal: false,
      ware_house_details: [],
      ware_house_bin_details: [],
      shipment_method_details: [],
      conditions: {},
      route: useRoute(),
      router: useRouter(),
      message: "",
      showTable: false,
      connectionString: "",
      customerDescription: [],
      company_details: [],
      company_filter: [],
      ids: [],
      tracking_details: "",
      companies_detail: [],
      total_count: 0,
    };
  },
  watch: {
    "route.query": {
      handler: async function (newValue, oldValue) {
        if (
          Object.keys(newValue)?.length &&
          JSON.stringify(newValue) !== JSON.stringify(oldValue) &&
          newValue.page != 1
        ) {
          await this.getPickingShippingCall(this.page);
        }
      },
    },
    conditions: {
      handler: _.debounce(async function (newValue, oldValue) {
        setQuerry(newValue, this.router);
      }, 1000),
      deep: true,
      lazy: true,
    },
  },
  computed: {
    all_shipment_computed() {
      return this.shipment_method_details
        ?.map((shipment_method) => shipment_method.name)
        .sort();
    },
  },
  methods: {
    onClickOutside() {
      return !this.loading;
    },

    showSearchModal() {
      this.searchModal = !this.searchModal;
    },

    async handleAdd() {
      this.onShowModel = true;
      this.isAddModel = true;
      this.pickingShippingData = {};
    },
    async addShipment() {
      let json_converter = convert_to_json_format(this.pickingShippingData);
      await add_picking_shipping_detials(json_converter, this.productID)
        .then((res) => {
          if (res.data.data.errors) {
            let snackBarMessage = "";
            for (let i = 0; i < res.data.data.errors?.length; i++) {
              if (snackBarMessage.includes(res.data.data.errors[i].message)) {
                snackBarMessage = res.data.data.errors[i].message + " ";
              }
            }
            this.emitter.emit("snackbar", {
              text: snackBarMessage,
              color: "error",
            });
          } else if (res.data.data.message) {
            let snackBarMessage = "";
            for (let i = 0; i < res.data.data.errors?.length; i++) {
              if (snackBarMessage.includes(res.data.data.errors[i].message)) {
                snackBarMessage += res.data.data.errors[i].message + " ";
              }
            }
            this.emitter.emit("snackbar", {
              text: snackBarMessage,
              color: "error",
            });
          } else if (res.data.success) {
            this.emitter.emit("snackbar", {
              text: `Shippment has been Added`,
              color: "error",
            });
            this.pickingShippingData = {};
            this.closeModel();
          } else {
            this.emitter.emit("snackbar", {
              text: `Shippment has not been Added`,
              color: "error",
            });
            this.pickingShippingData = {};
            this.closeModel();
          }
        })
        .catch((e) => {
          this.emitter.emit("snackbar", {
            text: e,
            color: "error",
          });
        });

      await this.getPickingShippingCall();
    },

    async ResetSearchModel() {
      this.conditions = {};
    },

    async closeModel() {
      this.onShowModel = false;
      this.isAddModel = false;
    },
    async hanldeClickRow(id) {
      this.snackBarMessage = "";
      this.loadingModel = true;
      this.onShowModel = true;

      try {
        const response = await get_picking_shipping_details_id(id);

        if (response?.data?.data?.length) {
          let data_from_response = response.data.data[0];
          this.pickingShippingData = convert_to_form_format(data_from_response);

          if (response?.tracking_details) {
            this.pickingShippingData.expectedDeleivery =
              response?.tracking_details["Delivery_Date"]?.includes("T")
                ? response?.tracking_details["Delivery_Date"]?.split("T")[0]
                : response?.tracking_details["Delivery_Date"];

            this.pickingShippingData.Delivery_Status =
              response?.tracking_details["Delivery_Status"];
          }

          this.loadingModel = false;
        } else {
          this.pickingShippingData = {};
          this.emitter.emit("snackbar", {
            text: `Picking Shipping details not available against ${id}`,
            color: "error",
          });
          this.loadingModel = false;
          this.closeModel();
        }
      } catch (err) {
        if (err.response.status === 400) {
          this.emitter.emit("snackbar", {
            text: "No courier service found against this tracking number",
            color: "error",
          });
        } else if (err.response.status === 500) {
          this.emitter.emit("snackbar", {
            text: "Something went wrong please try again later or contact support",
            color: "error",
          });
        } else if (err.response.status === 404) {
          this.emitter.emit("snackbar", {
            text: "Invalid tracking number",
            color: "error",
          });
        } else if (err.response.status === 503) {
          this.emitter.emit("snackbar", {
            text: "Servor is currently down please try again later",
            color: "error",
          });
        } else if (err.response.status === 401) {
          this.emitter.emit("snackbar", {
            text: "Something went wrong please try again",
            color: "error",
          });
        }

        this.loadingModel = false;

        this.closeModel();
      }
    },
    async getPickingShippingCall(page) {
      this.page = page || 1;
      let filters = this.route.query;
      this.loading = true;
      this.message = "";
      this.conditions.pageSize ? "" : (this.conditions.pageSize = 10);

      await get_picking_shipping_details(this.page, filters)
        .then((response) => {
          this.pickingShippingDetails = response.data.data;
          this.total_count = Math.ceil(
            response?.data?.totalRecords /
              (this.conditions.pageSize ? this.conditions.pageSize : 10)
          );
          this.pickingShippingDetails
            ? (this.message = "No Record Available")
            : "";
        })
        .catch((err) => {
          this.emitter.emit("snackbar", { text: err, color: "error" });
          this.pickingShippingDetails = [];
          this.message = "No Record Available";
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },
    async customerDescriptionCall() {
      await getCustomerDescription()
        .then((res) => {
          this.customerDescription = res.data?.data.sort((a, b) => {
            const aProcessed = this.removeSpecialCharacters(a);
            const bProcessed = this.removeSpecialCharacters(b);
            return aProcessed.localeCompare(bProcessed);
          });
        })
        .catch((err) => {
          this.emitter.emit("snackbar", { text: err, color: "error" });
        });
    },
    removeSpecialCharacters(s, cus) {
      return s.replace(/[^a-zA-Z]/g, "").toLowerCase();
    },
    async companiesCall() {
      await getCompanies()
        .then((res) => {
          this.companies_detail = res?.data?.data;
          this.companies_detail = this.companies_detail.sort((a, b) => {
            const aProcessed = this.removeSpecialCharacters(a);
            const bProcessed = this.removeSpecialCharacters(b);
            return aProcessed.localeCompare(bProcessed);
          });
        })
        .catch((err) => {
          this.emitter.emit("snackbar", { text: err, color: "error" });
        });
    },
    async getWareHouseCall() {
      await get_warehouse_details()
        .then((res) => {
          if (res?.data) {
            this.ware_house_details = res?.data?.data.sort();
          }
        })
        .catch((err) => {
          this.emitter.emit("snackbar", { text: err, color: "error" });
        });
    },
    async getWareHouseBinCall() {
      await get_warehouse_bin_details()
        .then((res) => {
          if (res.data) {
            this.ware_house_bin_details = res.data?.data;
          }
        })
        .catch((err) => {
          this.emitter.emit("snackbar", { text: err, color: "error" });
        });
    },
    async getShipmentCall() {
      await getShipmentMethod()
        .then((res) => {
          this.shipment_method_details = res.data;
        })
        .catch((err) => {
          this.emitter.emit("snackbar", { text: err, color: "error" });
        });
    },
  },
  async created() {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
    this.conditions = setCondition(this.route?.query);

    await this.getPickingShippingCall();
    await this.customerDescriptionCall();
    await this.getWareHouseCall();
    await this.getWareHouseBinCall();
    await this.companiesCall();
    await this.getShipmentCall();
    document.addEventListener("click", this.clickHandler);
  },
};
</script>
<style lang="scss" src="../assets/style/dashboard.scss" scoped></style>
