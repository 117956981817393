<template>
  <div>
  <button
  class=" 
   pa-3
   mt-6
   w-100
   border-raduis
   "
  style="background-color: rgb(97, 67, 225)"
  type="submit"
  @click="handleSubmit"
>
  Continue
</button>
</div>
</template>

<script>
export default {
  props: {
    handleEvent: Function,
  },
  methods: {
    async handleSubmit() {
      this.$emit("handleEvent");
    },
  },
};
</script>