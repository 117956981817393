import { axiosInstance } from "../index";

const get_picking_shipping_details = async (page, query_params) => {
  let params = { ...query_params };
  return await axiosInstance.get(
    `api/v1/picking-shipping-details?page=${page}&conditions=${encodeURIComponent(
      JSON.stringify(query_params)
    )}`,
    {
      params,
    }
  );
};
const get_picking_shipping_details_id = async (id) => {
  const response = await axiosInstance.get(
    `api/v1/picking-shipping-details/${id}`
  );
  try {
    if (response?.data?.data?.length) {
      const trackingNumber = response?.data?.data[0].trackingNumber;
      if (trackingNumber) {
        const tracking_details = await axiosInstance.get(
          `api/v1/tracking/${trackingNumber}`
        );
        response.tracking_details = tracking_details?.data;
      }
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
  return response;
};

const getShipmentMethod = async () => {
  let response = await axiosInstance.get("api/v1/shipmentMethod");
  return response.data;
};

const add_picking_shipping_detials = async (data) => {
  return await axiosInstance.post(
    `api/v1/picking-shipping-details/?productId=100001`,
    data
  );
};

const getCompanies = async () => {
  return await axiosInstance.get(`api/v1/companies`);
};

const getCustomerDescription = async () => {
  return await axiosInstance.get(`api/v1/customerDescription`);
};

export {
  get_picking_shipping_details_id,
  add_picking_shipping_detials,
  getCompanies,
  get_picking_shipping_details,
  getShipmentMethod,
  getCustomerDescription,
};
