<template>
  <div>
    <div class="search_form">
      <SelectFilter
        :options="all_companies"
        label="Company"
        paramName="company_details"
        :conditions="conditions"
        :loading="loading"
      />
      <SelectFilter
        label="Parts #"
        :options="customerDescription"
        paramName="customerDescription"
        :conditions="conditions"
        :loading="loading"
      />
      <SelectFilter
        :options="ware_house_detail"
        label="Warehouse"
        paramName="ware_house_search"
        :conditions="conditions"
        :loading="loading"
      />
      <SelectFilter
        :options="all_shipment_computed"
        label="Ship Method"
        paramName="shipment_search"
        :conditions="conditions"
        :loading="loading"
      />
      <SelectFilter
        :options="status"
        label="Shipping Status"
        paramName="shipment_status_search"
        :conditions="conditions"
        :loading="loading"
      />
      <div class="button">
        <v-btn @click="handleReset()" :disabled="loading"> Reset </v-btn>
      </div>
      <div class="button">
        <v-btn @click="add()" :disabled="loading">Create</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SelectFilter from "./SelectFilter.vue";
export default {
  components: {
    SelectFilter,
  },
  props: {
    customerDescription: {
      type: Array,
    },
    ware_house_detail: {
      type: Array,
    },
    conditions: {
      type: Object,
    },
    reset: {
      type: Function,
    },
    warehouse_bin_details: {
      type: Array,
    },
    all_companies: {
      type: Array,
    },
    all_shipment_computed: {
      type: Array,
    },
    handleAdd: {
      type: Function,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      status: ["Shipped", "Delivered"],
      created_date: ["less than 60 days"],
    };
  },
  methods: {
    add() {
      this.$emit("handleAdd");
    },
    handleReset() {
      this.$router.push({ query: {} });
      this.$emit("reset");
    },
  },
  async created() {},
};
</script>
<style lang="scss" src="../assets/css/Filter.scss" scoped></style>
