import store from '../store/store'
export default function (to, from, next) {
    store.dispatch('performSetAccesstoken', localStorage.getItem("access_token"))
    if (store.getters.getAccessToken) {
        if (to.path == "/dashboard") {
            next()
        }
        else {
            next({ name: "dashboard" })
        }
    } else {
        if (to.path != "/") {
            next({ name: "authenticate" })
        }
        else {
            next()
        }
    }
}