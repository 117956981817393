export const convert_to_form_format = (data_from_response) => {
    let converted_data = {}
    if(data_from_response.ShipmentMethod){
      converted_data.shipmentName = data_from_response.shipmentMethod
    }
    if(data_from_response.warehouse){
      converted_data.warehouseName = data_from_response.warehouse
    }
    if(data_from_response.warehouseBin){
      converted_data.warehouseBinName = data_from_response.warehouseBin
    }
    if (data_from_response.qtyPick ||data_from_response.qtyPick == 0) {
      converted_data.pickedQuantity = data_from_response.qtyPick;
    }
    if (data_from_response.qtyShip ||data_from_response.qtyShip == 0) {
      converted_data.shippedQuantity =data_from_response.qtyShip;        
    }
    if (data_from_response.trackingNumber ||data_from_response.trackingNumber == 0) {
      converted_data.trackingNumber =data_from_response.trackingNumber;
    }

    return converted_data;
  }