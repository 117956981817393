export const convert_to_json_format = (data) => {
    return {
      pickedQuantity: data.pickedQuantity,
      shippedQuantity: data.shippedQuantity,
      warehouse: {
        name: data.warehouseName,
      },
      warehouseBin: {
        name: data.warehouseBinName,
      },
      shipmentMethod: {
        id: data.shipmentId,
      },
      trackingNumber: data.trackingNumber,
      
    };
  }