const BASE_URL = 'https://onboard.decyphertech.com'
import axios from 'axios'

const authourise_request = axios.create({})

const handle_email  =  (email) =>{
    const request = axios.post(BASE_URL +  '/auth/login',
            {
                "email":email?.toLowerCase(),
            })

    return request;
}

const handle_otp = (email,pin) => {
    const request= axios.post(BASE_URL+'/auth/verify-pin',
    {
      "email":email?.toLowerCase(),
      "pin":pin
    }
    )
    return request
}

export{
    handle_email,
    handle_otp
}