import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import store from "./store/store.js";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "vue-toast-notification/dist/theme-sugar.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import mitt from "mitt";
const vuetify = new createVuetify({
  components,
  directives,
});

import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.min.css";

export default createVuetify({
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.red.darken1, // #E53935
          customNavy: "#34435c", // #FFCDD2
        },
      },
    },
  },
  components: {},
});
const emitter = new mitt();
const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
